import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modalClose } from '../../layout/redux/layoutActions'
import { Button, MaskValorMoedaex, Modal } from '../../components'
import { CheckoutProjetoProntoXD, cleanProjeto, setProjeto } from './redux/projetosActions'
import { iconeprojeto } from '../meusProjetos/redux/meusProjetos'
import { IF } from '../../helpers'
import { AddAlert } from '../../components'  // 🔹 Importando corretamente o AddAlert
import { preencheDadosNotaCpfCnpj } from '../upgradeConta/redux/upgradeContaActions'  // 🔹 Importando a função de validação
import { Input } from '../../components'  // 🔹 Importando corretamente o Input
import { formatarCPF, formatarCNPJ } from '../../helpers/utils'  // 🔹 Importando as funções de formatação



export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario, statusModal } = useSelector(state => state.layoutState)
  const { projeto } = useSelector(state => state.projetosState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../layout/nlsPopup/${sistema.idioma}.json`)
  const privado = usuario.uniqueId ? true : false

  //PARA O MODAL DE CPFCNPJ
  // Estado para o modal de CPF/CNPJ
  const [showCpfCnpjModal, setShowCpfCnpjModal] = useState(false);
  const [radioTipo, setRadioTipo] = useState('CPF');
  const [cpfCnpjInput, setCpfCnpjInput] = useState('');
  const [checkoutParams, setCheckoutParams] = useState(null);

  
  useEffect(()=> {

  }, [])


  const handleAdquirirProjeto = (projeto) => {
    const params = {
      id: projeto.Id,
      idPeriodo: projeto.valor.idPeriodo,
      moeda: usuario?.moedaIso4217
    };
  
    // Se for do Brasil e não tiver a permissão "VerHistoricoFaturas", mostrar modal de CPF/CNPJ
    if (usuario.codigoPais === 'BR' && (!usuario.permissoes || !('VerHistoricoFaturas' in usuario.permissoes))) {
      setCheckoutParams(params);
      setRadioTipo('CPF');
      setCpfCnpjInput('');
      setShowCpfCnpjModal(true);
    } else {
      dispatch(CheckoutProjetoProntoXD(params, nlsPopup));
    }
  };
 
  const handleEnviarCpfCnpj = async () => {
    if (!cpfCnpjInput) {
      dispatch(AddAlert('danger', nls.mensagemcpf.preenchecpfoucnpj))
      return
    }

    try {
      const result = await dispatch(preencheDadosNotaCpfCnpj(cpfCnpjInput))
      if (result === true) {
        const paramsWithCpfCnpj = { ...checkoutParams, cpfCnpj: cpfCnpjInput }
        setShowCpfCnpjModal(false)
        dispatch(CheckoutProjetoProntoXD(paramsWithCpfCnpj, nlsPopup))
      } else {
        dispatch(AddAlert('danger', nls.mensagemcpf.informacaoinvalida))
      }
    } catch (error) {
      dispatch(AddAlert('danger', nls.mensagemcpf.informacaoinvalida))
    }
  }



  const handleCheckboxSelect = e => {
    const params = {
      id: e.Id,
      idPeriodo: e.valor.idPeriodo,
      moeda: usuario?.moedaIso4217
    }
    dispatch(CheckoutProjetoProntoXD(params, nlsPopup))
  }

  const handleCloseModal = e => {
    dispatch([
      cleanProjeto(),
      modalClose()
    ]) 
  }  

  return (
    <>
      <Modal
        name={'modalprojeto'}
        title={
          <div className='box-banco-projetos-projeto-topinfo-info'>
            <div>{projeto?.Icone ? iconeprojeto(projeto?.Icone) : null}</div>
            <div>
              <label>{projeto?.Descricao}</label>
              <small>{projeto?.Segmento}</small>
            </div>
          </div>
        }
        size='medium'
        open={statusModal === 'adquirirProjetoModal'}
        close={() => handleCloseModal()}
        closeText={nls?.btnFechar}
        actions={
          <>
            <div className='modalprojeto-valores'>
              {projeto.periodo?.valorDesconto ? (
                <small className='valorvelho'>
                  {`${MaskValorMoedaex(projeto.periodo?.valorDesconto, sistema.idioma)}${projeto.periodo?.periodoDesconto ? ` / ${projeto.periodo?.periodoDesconto}` : ''}`}
                </small>
              ) : null}
              <div>
                <h4>{projeto.valor?.valor ? projeto.valor?.valor : 0} / {projeto.valor?.descricao}</h4>
                {projeto.valor?.percentualDesconto ? <span className='desconto'>{projeto.valor?.percentualDesconto} {nls.desconto}</span> : null}
              </div>
            </div>
            {privado ? (
              <Button
                color='primary'
                onClick={() => usuario?.permissoes?.VerAdquirirProjetoPronto ? handleAdquirirProjeto(projeto) : null}
                disabled={!usuario?.permissoes?.VerAdquirirProjetoPronto}
                title={!usuario?.permissoes?.VerAdquirirProjetoPronto ? nls.mensagem.apenasUserMaster : nls.btnAdquirirProjeto}
              >
                {nls?.btnAdquirirProjeto}
              </Button>
            ) : (
              <Button
                color='primary'
                onClick={() => null}
                title={nls.btnCadastrar}
              >
                {nls.btnCadastrar}
              </Button>
            )}
          </>
        }
      >
        <div>
          <div>
            <label>{nls.descricao}</label>
            <p>{projeto?.DescricaoLonga}</p>
          </div>
          <div>
            <label>{nls.empresas}</label>
            <p className='modalprojeto-empresas-virgula'>{projeto?.NomeEmpresasProjetoPronto}</p>
          </div>
          <div>
            <label>{nls.pais}</label>
            <p>{projeto?.Pais}</p>
          </div>
          <div className='modalprojeto-periodos'>
            <label>{nls.periodo}</label>
            <div>
              {projeto?.valores?.map(e => (
                <Button
                  key={e.id}
                  color={projeto.valor?.id === e.id ? 'primary circle' : 'link'}
                  onClick={() => dispatch(setProjeto({ name: 'valor', value: e }))}
                >
                  {e.descricao}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </Modal>
  
      {/* 🔹 Modal CPF/CNPJ Adicionado Aqui */}
      <Modal
        name='modal-cpf-cnpj'
        title={nls.formulariocpf.titulo} // "Cadastro CPF/CNPJ"
        open={showCpfCnpjModal}
        close={() => setShowCpfCnpjModal(false)}
        closeText={nls?.btnFechar}
        actions={
          <>
            <Button color='default' onClick={() => setShowCpfCnpjModal(false)}>
              {nls.formulariocpf.cancelar} 
            </Button>
            <Button color='primary' onClick={handleEnviarCpfCnpj}>
              {nls.formulariocpf.enviar}
            </Button>
          </>
        }
      >
        <div>
          {/* Bloco para CPF */}
          <div style={{ marginBottom: '1rem' }}>
            <label>
              <input
                type="radio"
                name="tipoDocumento"
                value="CPF"
                checked={radioTipo === 'CPF'}
                onChange={() => {
                  setRadioTipo('CPF');
                  setCpfCnpjInput('');
                }}
              />{' '}
              CPF
            </label>
            {radioTipo === 'CPF' && (
              <div style={{ marginTop: '0.5rem' }}>
                <Input
                  type='text'
                  placeholder={nls.formulariocpf.placeholdercpf}
                  value={cpfCnpjInput}
                  action={a => setCpfCnpjInput(formatarCPF(a.value))}
                />
              </div>
            )}
          </div>
  
          {/* Bloco para CNPJ */}
          <div style={{ marginBottom: '1rem' }}>
            <label>
              <input
                type="radio"
                name="tipoDocumento"
                value="CNPJ"
                checked={radioTipo === 'CNPJ'}
                onChange={() => {
                  setRadioTipo('CNPJ');
                  setCpfCnpjInput('');
                }}
              />{' '}
              CNPJ
            </label>
            {radioTipo === 'CNPJ' && (
              <div style={{ marginTop: '0.5rem' }}>
                <Input
                  type='text'
                  placeholder={nls.formulariocpf.placeholdercnpj}
                  value={cpfCnpjInput}
                  action={a => setCpfCnpjInput(formatarCNPJ(a.value))}
                />
              </div>
            )}
          </div>
  
          <hr style={{ borderColor: '#ccc' }} />
          <p>{nls.formulariocpf.info}</p>
        </div>
      </Modal>
    </>
  );
}