import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IcoAvaliacoes, IcoCircleClose, IcoEqualizer, IcoMoney, IcoPasteArchive, List, LoadingContent, MaskData, Popover } from '../../components'

import './minhasAssinaturas.scss'
import { useEffect } from 'react'
import { modalAlertaClean, modalAlertaInfo, modalOpen, verificaConsumoConta } from '../../layout/redux/layoutActions'
import { cancelarAssinatura, listarAssinaturas, setMinhaAssinatura } from './redux/minhasAssinaturasActions'
import AdquirirAtivos from './content/adquirirAtivos'
import AlterarAssinatura from './content/alterarAssinatura'
import EconomizarAssinatura from './content/economizarAssinatura'
import AdquirirAvaliacoes from './content/adquirirAvaliacoes'
import { typeStatusCustom } from '../meusProjetos/redux/meusProjetos'
import AlterarAssinaturaProjeto from './content/alterarAssinaturaProjeto'

import { PopoverCentralAjuda } from '../../components/popovercentralajuda/popovercentralajuda'


export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../layout/nlsPopup/${sistema.idioma}.json`)
  const { listaAssinaturas, paginate } = useSelector(state=> state.minhasAssinaturasState)

  const nlsContexto = require(`../centralAjuda/nlscontexto/${sistema.idioma}.json`)

  const idarray = [110];
  const idarray1 = [111];


  useEffect(()=> {
    dispatch([verificaConsumoConta(), listarAssinaturas()])
  }, [sistema.idioma])

  const headerDefault = [
    {
      column: 'descricao',
      text: nls.descricao,
      className: '',
    },
    {
      column: 'descricaoTipo',
      text: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {nls.tipo}
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
        </div>
      ),
      sort: '',
      className: '',
    },
    {
      column: 'statusCustom',
      text: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {nls.status}
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray1} />
        </div>
      ),
      sort: '',
      className: '',
    },
    {
      column: 'periodo',
      text: nls.periodo,
      sort: '',
      className: '',
    },
    {
      column: 'valor',
      text: nls.valor,
      sort: '',
      className: '',
    },
    {
      column: 'dataRenovacaoCustom',
      text: nls.dataRenovacao,
      sort: '',
      className: '',
    },
    {
      column: 'actions',
      text: '',
      sort: '',
      className: 'actions onlysvg',
    }
  ]

  const handleConfigurar = (e, s) => {
    console.warn(e, 'handleConfigurar');
    if (s) {
      dispatch(setMinhaAssinatura(e))
      dispatch(modalOpen(s))
    }
  }

  const handleConcordar = (e, status) => {
    if (status === 'cancelarAssinatura') {
      dispatch(cancelarAssinatura(e, nlsPopup))
    }
    dispatch(modalAlertaClean())
  }
  
  const handleVizualizar = e => {
    console.warn(e, 'handleVizualizar');
    dispatch(modalAlertaInfo({
      ...nlsPopup[e.idProjetoPronto === null ? 5 : 6], 
      btnAction: ()=> handleConcordar(e, 'cancelarAssinatura'), 
      textos: {
        'DataRenovacaoAssinatura': MaskData(e.dataRenovacaoAssinatura, 'DD/MM/YYYY', sistema.idioma)
      }
    }))
  }

  return (
    <div className='box-minhas-assinaturas'>
      <div className='box-content'>
        <div className='box-content-topinfo'>
          <h3>{nls.titleList} </h3>
        </div>
        <LoadingContent
          show={false}
        >
          <List
            header={headerDefault}
            data={listaAssinaturas}
            listCustom={e=> {
              const item = e
              const statusInfo = typeStatusCustom(e.statusId)
              item.statusCustom = <span>{e.status}</span>

              item.dataRenovacaoCustom = <span>{MaskData(e.dataRenovacaoAssinatura, 'DD/MM/YYYY', sistema.idioma)}</span>
              item.actions = <div className='rigth'>
                {e.idProjetoPronto === null && e.statusId !== 'SV' ? 
                  <>
                    <Button
                      type='link'
                      onClick={()=> handleConfigurar(e, 'assinaturas-adquirir-avaliacoes')}
                      title={nls.comprarAvaliacoes}
                    ><IcoAvaliacoes /></Button>
                    <Button
                      type='link'
                      onClick={()=> handleConfigurar(e, 'assinaturas-adquirir-ativos')}
                      title={nls.comprarAtivos}
                    ><IcoEqualizer /></Button>
                  </>
                :null}
                {e.statusId === 'SA' ?
                  <>
                    <Button
                      type='link'
                      onClick={()=> handleConfigurar(e, e.idProjetoPronto === null ? 'assinaturas-alterar-projeto' : 'assinaturas-alterar')}
                      title={nls.alterarAssinatura}
                    ><IcoPasteArchive /></Button>
                  
                  </>
                :null}
                {e.statusId === 'SA' && e.periodoId < 4 ?
                  <>
                    <Button
                      type='link'
                      onClick={()=> handleConfigurar(e,'assinaturas-economizar')}
                      title={nls.economizar}
                    ><IcoMoney /></Button>
                  </>
                :null}
                {e.statusId === 'SA' || e.statusId === 'SAA' ?
                  <>
                    <Button
                      type='link'
                      color='primary'
                      onClick={()=> handleVizualizar(e)}
                      title={nls.cancelar}
                    ><IcoCircleClose /></Button>
                  </>
                :null}
              </div>

              return item
            }}
            // paginate={paginate}
            // actionPaginate={(e)=> handlePaginate(e)}
            // handlePerPage={(e)=> handlePerPage(e)}
            // nls={nls.paginacao}
          />
        </LoadingContent>
      </div>
      <AdquirirAtivos/>
      <AlterarAssinatura/>
      <AlterarAssinaturaProjeto/>
      <EconomizarAssinatura/>
      <AdquirirAvaliacoes/>
    </div>
  )
}
