import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './projetos.scss'
import {
  CheckoutProjetoProntoXD,
  getProjeto,
  listarProjetos,
  setProjetosFiltro,
  listarProjetosSemLoading
} from './redux/projetosActions'
import {
  Button,
  LoadingContent,
  MaskValorMoedaex,
  Modal,
  Input,
  AddAlert,
  Loading
} from '../../components'
import { iconeprojeto } from '../meusProjetos/redux/meusProjetos'
import { listarPaises, listarSegmentos, modalOpen } from '../../layout/redux/layoutActions'
import AdquirirModal from './adquirirModal'
import ProjetosFiltros from './projetosFiltros'
import { idiomaCustom, IF } from '../../helpers'
import { listaPeriodos } from '../minhasAssinaturas/redux/minhasAssinaturasActions'
import { PopoverCentralAjuda } from '../../components/popovercentralajuda/popovercentralajuda'
import { formatarCPF, formatarCNPJ } from '../../helpers/utils'
import { preencheDadosNotaCpfCnpj } from '../upgradeConta/redux/upgradeContaActions'

export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario, listas: { segmentos, paises } } = useSelector(state => state.layoutState)
  const { projetos, listas, paginate } = useSelector(state => state.projetosState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../layout/nlsPopup/${sistema.idioma}.json`)
  const nlsContexto = require(`../centralAjuda/nlscontexto/${sistema.idioma}.json`)
  const idarray = [105]
  const privado = usuario.uniqueId ? true : false

  // Estados para o modal de CPF/CNPJ
  const [showCpfCnpjModal, setShowCpfCnpjModal] = useState(false)
  const [radioTipo, setRadioTipo] = useState('CPF')
  const [cpfCnpjInput, setCpfCnpjInput] = useState('')
  const [checkoutParams, setCheckoutParams] = useState(null)

  const [loadingLocal, setLoadingLocal] = useState(false);

  useEffect(() => {
    // Sempre que precisar listar projetos:
    setLoadingLocal(true);

    dispatch(
      listarProjetosSemLoading({
        idioma: sistema.idioma, 
        // ...outros params que você precisar
      })
    )
      .finally(() => {
        setLoadingLocal(false);
      });
  }, [sistema.idioma]);


  useEffect(() => {
    dispatch(listaPeriodos({ idioma: usuario.idioma.id }))
    dispatch(listarSegmentos({ idioma: idiomaCustom(sistema.idioma) }))
    dispatch(listarPaises({ idioma: idiomaCustom(sistema.idioma) }))
  }, [])

  useEffect(() => {
    if (segmentos.length && usuario?.segmentoInteresseId) {
      const segmentoSelected = segmentos.find(e => e.id === usuario.segmentoInteresseId)
      dispatch(setProjetosFiltro({ name: 'segmentos', value: segmentoSelected }))
    }
  }, [segmentos])

  useEffect(() => {
    if (sistema.pais) {
      dispatch(setProjetosFiltro({ name: 'paises', value: sistema.pais }))
    }
  }, [paises])

  // Dispara o checkout direto (sem CPF/CNPJ) para um projeto
  const handleCheckboxSelect = (e) => {
    const params = {
      id: e.Id,
      idPeriodo: 4, // ou use e.valor.idPeriodo conforme necessário
      moeda: usuario?.moedaIso4217 || sistema?.moeda
    }
    dispatch(CheckoutProjetoProntoXD(params, nlsPopup))
  }

  // Fluxo "ver mais" (saibamais)
  const handleVerMais = (e) => {
    dispatch([
      getProjeto({ ...e, valor: e.valores[e.valores.length - 1] }),
      modalOpen('adquirirProjetoModal')
    ])
  }

  // Fluxo para a ação "adquirir" projeto
  const handleAdquirirProjeto = (projeto) => {
    const params = {
      id: projeto.Id,
      idPeriodo: 4, // ajuste conforme a lógica do seu sistema
      moeda: usuario?.moedaIso4217 || sistema?.moeda
    }

    console.log("usuario.codigoPais:", usuario.codigoPais)
    console.log("usuario.permissoes:", usuario.permissoes)

    // Se for do Brasil e a propriedade "VerHistoricoFaturas" NÃO existir em usuario.permissoes:
    if (usuario.codigoPais === 'BR' && (!usuario.permissoes || !('VerHistoricoFaturas' in usuario.permissoes))) {
      console.log("Condição atendida: exibindo modal CPF/CNPJ")
      setCheckoutParams(params)
      setRadioTipo('CPF')
      setCpfCnpjInput('')
      setShowCpfCnpjModal(true)
    } else {
      console.log("Condição não atendida: indo direto ao checkout")
      dispatch(CheckoutProjetoProntoXD(params, nlsPopup))
    }
  }

  // "Action" para o componente BoxProjeto
  const handleAction = (projeto, tipo) => {
    if (tipo === 'saibamais') {
      handleVerMais(projeto)
    } else if (tipo === 'adquirir') {
      handleAdquirirProjeto(projeto)
    }
  }

  // Função chamada ao clicar em "Enviar" no modal de CPF/CNPJ
  const handleEnviarCpfCnpj = async () => {
    if (!cpfCnpjInput) {
      //alert("Por favor, preencha o CPF ou CNPJ.")
      dispatch(AddAlert('danger', nls.mensagemcpf.preenchecpfoucnpj))
      return
    }
    try {
      // Chama a action para validar CPF/CNPJ (a API retorna true se válido)
      const result = await dispatch(preencheDadosNotaCpfCnpj(cpfCnpjInput))
      if (result === true) {
        // Se válido, junta o CPF/CNPJ aos parâmetros e dispara o checkout
        const paramsWithCpfCnpj = { ...checkoutParams, cpfCnpj: cpfCnpjInput }
        setShowCpfCnpjModal(false)
        dispatch(CheckoutProjetoProntoXD(paramsWithCpfCnpj, nlsPopup))
      } else {
        //alert("CPF/CNPJ inválido ou não aprovado pelo servidor.")
        dispatch(AddAlert('danger', nls.mensagemcpf.informacaoinvalida))
      }
    } catch (error) {
      //alert("Ocorreu um erro ao validar CPF/CNPJ: " + error.message)
      dispatch(AddAlert('danger', nls.mensagemcpf.informacaoinvalida))
    }
  }

  return (
    <>
      {/* Se estiver carregando local, mostramos o <Loading> com a tradução do nls */}
      {loadingLocal && (
        <Loading title={nls.mensagem.carregando} />
      )}
    <div className='box-banco-projetos'>
      <div className='box-title'>
        <h3 style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {nls?.title}
          <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
        </h3>
        <ProjetosFiltros />
      </div>
      <div className='box-banco-projetos-container'>
        <div className='box-banco-projetos-content'>
          <LoadingContent show={false}>
            {projetos?.map(e => {
              // Trata os valores do projeto
              const valores = e.valores?.map(v => {
                const periodo = listas.periodos?.find(f => f.id === v.idPeriodo)
                return { ...v, ...periodo }
              })
              e = { ...e, valores }
              return (
                <BoxProjeto
                  nls={nls}
                  idioma={sistema.idioma}
                  moeda={usuario?.moedaIso4217 || sistema?.moeda}
                  projeto={e}
                  action={(d, s) => {
                    if (s === 'saibamais') {
                      handleVerMais(e)
                    } else if (s === 'adquirir') {
                      handleAdquirirProjeto(e)
                    }
                  }}
                  key={e.Id}
                />
              )
            })}
          </LoadingContent>
        </div>
      </div>
      <AdquirirModal />

      {/* Modal de CPF/CNPJ com formatação */}
      <Modal
        name='modal-cpf-cnpj'
        title= {nls.formulariocpf.titulo}    //"Cadastro CPF/CNPJ"
        open={showCpfCnpjModal}
        close={() => setShowCpfCnpjModal(false)}
        closeText="Fechar"
        actions={
          <>
            <Button color='default' onClick={() => setShowCpfCnpjModal(false)}>
            {nls.formulariocpf.cancelar} 
            </Button>
            <Button color='primary' onClick={handleEnviarCpfCnpj}>
            {nls.formulariocpf.enviar}
            </Button>
          </>
        }
      >
        <div>
          {/* Bloco para CPF */}
          <div style={{ marginBottom: '1rem' }}>
            <label>
              <input
                type="radio"
                name="tipoDocumento"
                value="CPF"
                checked={radioTipo === 'CPF'}
                onChange={() => {
                  setRadioTipo('CPF')
                  setCpfCnpjInput('')
                }}
              />{' '}
              CPF
            </label>
            {radioTipo === 'CPF' && (
              <div style={{ marginTop: '0.5rem' }}>
                <Input
                  type='text'
                  placeholder={nls.formulariocpf.placeholdercpf}
                  value={cpfCnpjInput}
                  action={a => {
                    const valorDigitado = a.value
                    const cpfFormatado = formatarCPF(valorDigitado)
                    setCpfCnpjInput(cpfFormatado)
                  }}
                />
              </div>
            )}
          </div>

          {/* Bloco para CNPJ */}
          <div style={{ marginBottom: '1rem' }}>
            <label>
              <input
                type="radio"
                name="tipoDocumento"
                value="CNPJ"
                checked={radioTipo === 'CNPJ'}
                onChange={() => {
                  setRadioTipo('CNPJ')
                  setCpfCnpjInput('')
                }}
              />{' '}
              CNPJ
            </label>
            {radioTipo === 'CNPJ' && (
              <div style={{ marginTop: '0.5rem' }}>
                <Input
                  type='text'
                  placeholder={nls.formulariocpf.placeholdercnpj}
                  value={cpfCnpjInput}
                  action={a => {
                    const valorDigitado = a.value
                    const cnpjFormatado = formatarCNPJ(valorDigitado)
                    setCpfCnpjInput(cnpjFormatado)
                  }}
                />
              </div>
            )}
          </div>

          <hr style={{ borderColor: '#ccc' }} />
          <p>{nls.formulariocpf.info}</p>
        </div>
      </Modal>     
    </div>
    </>
  )
}

const BoxProjeto = ({ nls, idioma, moeda, projeto, action = () => null }) => {
  const { usuario } = useSelector(state => state.layoutState)
  const privado = usuario.uniqueId ? true : false

  return (
    <div key={projeto.Id} className='box-banco-projetos-projeto'>
      <div className='box-banco-projetos-projeto-topinfo'>
        <div className='box-banco-projetos-projeto-topinfo-info'>
          <div>{iconeprojeto(projeto.Icone)}</div>
          <div>
            <label>{projeto.Descricao}</label>
            <small>{projeto.Segmento}</small>
          </div>
        </div>
        <div>
          <strong>{projeto?.valorAnualCobradoMensal} / {nls.mes}</strong>
          <label>
            <small>{nls.cobradoAnualmente}</small>
          </label>
        </div>
      </div>
      <div className='box-banco-projetos-projeto-content'>
        <p className='box-banco-projetos-projeto-content-descricao'>
          {projeto.DescricaoLonga}
        </p>
        <div className='colflex col2'>
          <div>
            <label>{nls.empresas}</label>
            <span>{projeto.NomeEmpresasProjetoPronto}</span>
          </div>
          <div>
            <label>{nls.pais}</label>
            <span>{projeto.Pais}</span>
          </div>
        </div>
      </div>
      <div className='box-banco-projetos-projeto-btns'>
        <Button
          color='primary'
          variant='outlined'
          onClick={() => action(projeto, 'saibamais')}
        >
          {nls.saibaMais}
        </Button>
        {privado ? (
          <Button
            color='primary'
            onClick={() =>
              usuario?.permissoes?.VerAdquirirProjetoPronto
                ? action(projeto, 'adquirir')
                : null
            }
            disabled={!usuario?.permissoes?.VerAdquirirProjetoPronto}
            title={
              !usuario?.permissoes?.VerAdquirirProjetoPronto
                ? nls.mensagem.apenasUserMaster
                : nls.btnAdquirirProjeto
            }
          >
            {nls.btnAdquirirProjeto}
          </Button>
        ) : (
          <Button
            color='primary'
            onClick={() => null}
            title={nls.btnCadastrar}
          >
            {nls.btnCadastrar}
          </Button>
        )}
      </div>
    </div>
  )
}
