import apiBase from "../../../helpers/apiBase"
import { modalAlertaClean, modalAlertaInfo, modalClose, loaded, loading } from "../../../layout/redux/layoutActions";

export const setListaAssinaturas = payload => ({
  type: 'LIST_MINHAS_ASSINATURAS',
  payload
})


export const listarAssinaturas = e => {
  console.warn(e, 'listarAssinaturas');
  return dispatch => {
    // api.get(`/Projeto/ListaProjetos`)
    dispatch(loading())
    apiBase.post(`/v4/api/ListaAssinaturasXD`)
    .then(({data}) => {
      console.log(data, 'listarAssinaturas data');
      dispatch(setListaAssinaturas(data))
    })
    .catch(err => {
        console.error(err, 'listarAssinaturas');
    })
    .finally(()=> {
      dispatch(loaded())
    })
  }
}


// src\modules\upgradeConta\redux\upgradeContaActions.js
export const preencheDadosNotaCpfCnpj = (cpfCnpj) => {
  return async (dispatch) => {
    try {
      dispatch(loading())  // se quiser exibir spinner
      //const { data } = await apiBase.post(`/v4/api/PreencheDadosNota/PreencheDadosCpfCnpj/${cpfCnpj}`)
      const { data } = await apiBase.post(`/v4/api/PreencheDadosNota/PreencheDadosCpfCnpj/${encodeURIComponent(cpfCnpj)}`)
      dispatch(loaded())   // encerra spinner
      return data          // retorna o valor para quem chamou
    } catch (error) {
      dispatch(loaded())
      throw error          // relança o erro para o componente tratar
    }
  }
}




export const checkoutContaXD = (e, nlsPopup) => {
  console.warn(e, 'listarAssinaturas');
  return dispatch => {
    // api.get(`/Projeto/ListaProjetos`)
    dispatch(loading())
    apiBase.post(`/v4/api/CheckoutContaXD`, e)
    .then(({data}) => {
      console.log(data, 'CheckoutContaXD data');
      // dispatch(setListaAssinaturas(data))
      //window.open(data.urlCheckout, '_blank'); //ISSO DÁ PROBLEMA PORQUE O BROWSER NÃO ACEITA DEPOIS DE 2 APIS ASYNC
      window.location.href = data.urlCheckout
      dispatch(modalClose())
    })
    .catch(err => {
        console.error(err, 'CheckoutContaXD');
        dispatch(modalClose())
        dispatch(modalAlertaInfo({
          ...nlsPopup['7'], 
          btnAction: ()=> dispatch(modalAlertaClean()),
          show: true,
        }))
    })
    .finally(()=> {
      dispatch(loaded())
    })
  }
}

