import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Input, Modal, AddAlert } from '../../../components'
import { modalClose } from '../../../layout/redux/layoutActions'
import { calculadoraContaEmpresarial, listaPeriodos, listarAtivos, setAtivos } from '../../minhasAssinaturas/redux/minhasAssinaturasActions'
import { checkoutContaXD, preencheDadosNotaCpfCnpj } from '../redux/upgradeContaActions'

import { PopoverCentralAjuda } from '../../../components/popovercentralajuda/popovercentralajuda'
import apiBase from "../../../helpers/apiBase"
import { loaded, loading } from "../../../layout/redux/layoutActions";
import { formatarCPF, formatarCNPJ } from '../../../helpers';


export default () => {
  const dispatch = useDispatch()
  const { sistema, usuario, statusModal } = useSelector(state => state.layoutState)
  const { listas, ativos, avaliacoes } = useSelector(state=> state.upgradeContaState)
  const nls = require(`../nls/${sistema.idioma}.json`)
  const nlsPopup = require(`../../../layout/nlsPopup/${sistema.idioma}.json`)
  const [form, setForm] = useState({ periodo: { "id": 4, "descricao": "ano", "percentualDesconto": "25 %" } })

  const nlsContexto = require(`../../centralAjuda/nlscontexto/${sistema.idioma}.json`)
  const idarray = [117];

  // ESTADOS para o modal CPF/CNPJ
  const [showCpfCnpjModal, setShowCpfCnpjModal] = useState(false)
  const [radioTipo, setRadioTipo] = useState('CPF')   // Valor inicial no radio
  const [cpfCnpjInput, setCpfCnpjInput] = useState('')
  const [checkoutParams, setCheckoutParams] = useState(null)

  useEffect(()=> {
    if (statusModal === 'comprar-ativos') {
      dispatch(listarAtivos({
        consumoConta: sistema.consumoConta, 
        moeda: usuario.moedaIso4217, 
        tipo: 'CE', 
        status: 'init', 
        idioma: sistema.idiomaIdValue
      }))
      dispatch(listaPeriodos({idioma: sistema.idiomaIdValue}))
    }
  }, [statusModal, sistema.idioma, sistema.consumoConta])

  const handleChange = e => {
    const listaAtivos = ativos.map(a=> a.id === e.id ? {...a, ...e} : a )
    dispatch(setAtivos(e))
    // Se quiser recalcular em tempo real sem blur, descomente:
    // dispatch(calculadoraContaEmpresarial({ativos: listaAtivos, moeda: usuario.moedaIso4217}))
  }

  const handleChangeBlur = e => {
    const listaAtivos = ativos.map(a=> a.id === e.id ? {...a, ...e} : a )
    dispatch(setAtivos(e))
    dispatch(calculadoraContaEmpresarial({ativos: listaAtivos, moeda: usuario.moedaIso4217}))
  }

  const handleCancelar = e => {
    dispatch(modalClose())
  }


  // >>>>>>>>>>>>>>> AQUI FAZEMOS O FLUXO DE ABRIR O MODAL CPF/CNPJ ANTES DO CHECKOUT <<<<<<<<<<<<<<<
  const handleSalvar = (form) => {
    const hasAtivos = ativos.filter(a => a.quantidadeDesejada > 0)
    if (!hasAtivos.length) return
  
    // Monta os parâmetros para o checkout
    const params = {
      moeda: usuario.moedaIso4217,
      idPeriodo: form.periodo.id,
      ativos: [
        ...ativos,
        {
          descricao: "Avaliações mensais",
          id: 6,
          quantidadeDesejada: avaliacoes.totalQueriesSemQueryPura30,
          valor: avaliacoes.totalQueriesSemQueryPura30
        }
      ]
    }
  
    // Se o usuário for do Brasil e não tiver a permissão 'VerHistoricoFaturas',
    // mostra o modal de CPF/CNPJ, caso contrário, vai direto para o checkout.
    if (usuario.codigoPais === 'BR' && (!usuario.permissoes || !('VerHistoricoFaturas' in usuario.permissoes))) {
      // Guarda os parâmetros para usar posteriormente no checkout
      setCheckoutParams(params)
      // Zera os campos do modal de CPF/CNPJ
      setRadioTipo('CPF')
      setCpfCnpjInput('')
      // Fecha o modal da calculadora e abre o modal de CPF/CNPJ
      dispatch(modalClose())
      setShowCpfCnpjModal(true)
    } else {
      dispatch(checkoutContaXD(params, nlsPopup))
    }
  }

  


  // >>>>>>>>>>>>>>> O QUE ACONTECE AO CLICAR NO BOTÃO "ENVIAR" DO MODAL CPF/CNPJ <<<<<<<<<<<<<<<
  const handleEnviarCpfCnpj = async () => {
    if (!cpfCnpjInput) {
      //alert("Por favor, preencha o CPF ou CNPJ.")
      dispatch(AddAlert('danger', nls.mensagemcpf.preenchecpfoucnpj))
      return
    }
    try {
      // Chama a action preencheDadosNotaCpfCnpj
      const data = await dispatch(preencheDadosNotaCpfCnpj(cpfCnpjInput))
      if (data === true) {
        // Fechamos o modal CPF/CNPJ
        setShowCpfCnpjModal(false)
        // E chamamos o checkout
        dispatch(checkoutContaXD(checkoutParams, nlsPopup))
      } else {
        dispatch(AddAlert('danger', nls.mensagemcpf.informacaoinvalida))
        //alert("API retornou false ou algo inesperado na validação de CPF/CNPJ.")
      }
    } catch (error) {
      console.error(error)
      //alert("Ocorreu um erro ao validar CPF/CNPJ.")
      dispatch(AddAlert('danger', nls.mensagemcpf.informacaoinvalida))
    }
  }



  

  const handleChangePeriodo = e => {
    setForm({...form, periodo: e})
  }

  const valorTotal = e => {
    const val = e?.find(f=> Number(f.id) === Number(form.periodo.id))
    return val?.valor
  }

  return (
    <>
      {/* MODAL PRINCIPAL: Selecionar Ativos e Período */}
      <Modal
        name='comprar-ativos'
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {nls.adquirirContaEmpresarial}
            <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} />
          </div>
        }
        open={statusModal === 'comprar-ativos' ? true : false}
        close={() => handleCancelar()}
        closeText={nls.btnFechar}
        actions={
          <>
            <Button
              color='default'
              onClick={()=> handleCancelar()}
            >
              {nls.cancelar}
            </Button>
            <Button
              color='primary'
              onClick={()=> handleSalvar(form)}
            >
              {nls.adquirirContaEmpresarial}
            </Button>
          </>
        }
      >
        <div>
          <div className='periodos-lista'>
            <strong>{nls.selecionePeriodo}</strong>
            <div>
              {listas?.periodos?.map(periodoItem => {
                return (
                  <div key={periodoItem.id}>
                    <Button
                      type='btn circle'
                      color={form?.periodo?.id === periodoItem.id ? 'primary' : 'default'}
                      size='small'
                      onClick={()=> handleChangePeriodo(periodoItem)}
                    >
                      {periodoItem.descricao}
                    </Button>
                  </div>
                )
              })}
            </div>
          </div>

          <div className='ativos-lista-title'>
            <h5>{nls.adicionarAtivos}</h5>
          </div>

          <div className='ativos-lista'>
            {ativos.map(e=> {
              const min = e.id <= 2 ? e.id : 0
              return (
                <div key={e.id} className='ativos-lista-item'>
                  <div className='ativos-lista-item-info'>
                    <strong>{e.descricao}</strong>
                  </div>
                  <div className='ativos-lista-item-input'>
                    <Input 
                      type='number'
                      value={e.quantidadeDesejada}
                      action={a=> handleChange({...e, quantidadeDesejada: a.value})}
                      actionBlur={a=> handleChangeBlur({...e, quantidadeDesejada: a.value})}
                      min={min}
                    />
                  </div>
                </div>
              )
            })}
            <div className='ativos-lista-item'>
              <div className='ativos-lista-item-info'>
                <strong>{nls.avaliacoesMensais}</strong>
              </div>
              <div className='ativos-lista-item-input'>
                <div className='flag-primary'>
                  {avaliacoes.totalQueriesSemQueryPura30}
                </div>
              </div>
            </div>
          </div>

          <div className='ativos-info'>
            <span>{nls.valorTotalAssinatura}: <strong>{valorTotal(avaliacoes.valores)}</strong> </span>
          </div>
        </div>
      </Modal>

      {/* MODAL SECUNDÁRIO: CPF/CNPJ */}
      <Modal
  name='modal-cpf-cnpj'
  title={nls.formulariocpf.titulo}
  open={showCpfCnpjModal}
  close={() => setShowCpfCnpjModal(false)}
  closeText="Fechar"
  actions={
    <>
      <Button
        color='default'
        onClick={() => setShowCpfCnpjModal(false)}
      >
        {nls.formulariocpf.cancelar}
      </Button>
      <Button
        color='primary'
        onClick={handleEnviarCpfCnpj}
      >
        {nls.formulariocpf.enviar}
      </Button>
    </>
  }
>
  <div>
    {/* Bloco para CPF */}
    <div style={{ marginBottom: '1rem' }}>
      <label>
        <input
          type="radio"
          name="tipoDocumento"
          value="CPF"
          checked={radioTipo === 'CPF'}
          onChange={() => {
            setRadioTipo('CPF')
            setCpfCnpjInput('')
          }}
        />
        {' '}CPF
      </label>
      {radioTipo === 'CPF' && (
        <div style={{ marginTop: '0.5rem' }}>
          <Input
            type='text'
            placeholder={nls.formulariocpf.placeholdercpf}
            value={cpfCnpjInput}
            // Ajuste aqui: use 'action' para atualizar
            //action={a => setCpfCnpjInput(a.value)}
            action={a => {
              // 1) Pega o texto digitado
              const valorDigitado = a.value
              // 2) Formata
              const cpfFormatado = formatarCPF(valorDigitado)
              // 3) Salva no state
              setCpfCnpjInput(cpfFormatado)
            }}


          />
        </div>
      )}
    </div>

    {/* Bloco para CNPJ */}
    <div style={{ marginBottom: '1rem' }}>
      <label>
        <input
          type="radio"
          name="tipoDocumento"
          value="CNPJ"
          checked={radioTipo === 'CNPJ'}
          onChange={() => {
            setRadioTipo('CNPJ')
            setCpfCnpjInput('')
          }}
        />
        {' '}CNPJ
      </label>
      {radioTipo === 'CNPJ' && (
        <div style={{ marginTop: '0.5rem' }}>
          <Input
            type='text'
            placeholder={nls.formulariocpf.placeholdercnpj}
            value={cpfCnpjInput}
            // Ajuste aqui: use 'action' para atualizar
            //action={a => setCpfCnpjInput(a.value)}
            action={a => {
              const valorDigitado = a.value
              const cnpjFormatado = formatarCNPJ(valorDigitado)
              setCpfCnpjInput(cnpjFormatado)
            }}
          />
        </div>
      )}
    </div>

    <hr style={{ borderColor: '#ccc' }} />

    <p>{nls.formulariocpf.info}</p>
  </div>
</Modal>

    </>
  )
}
