import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, MaskTelefone, MaskTelefoneInt, SelectDefault, verifySelectValue } from '../../components'
import { listarMeusDados, setInfoMeusDados, listarIdiomas } from './redux/meusDadosActions'
//import { listarIdiomas } from '../../layout/redux/layoutActions'
import { PopoverCentralAjuda } from '../../components/popovercentralajuda/popovercentralajuda'


export default () => {
  const dispatch = useDispatch()
  const { erro, meusDados } = useSelector(state => state.meusDadosState)
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const [senhaVisivel, setSenhaVisivel] = useState(false)
  const { usuarios } = useSelector(state=> state.usuariosState)

  const nlsContexto = require(`../centralAjuda/nlscontexto/${sistema.idioma}.json`)

  const idarray = [108]; 

  useEffect(()=> {
    dispatch(listarMeusDados())
    
  }, [])

  //DEPOIS QUE TEM CERTEZA QUE O IDIOMA MUDOU, AÍ CHAMA LISTARIDIOMAS PARA ATUALIZAR A LISTA DE IDIOMAS EM SISTEMA
  useEffect(() => {
    if (meusDados?.idioma) {
      dispatch(listarIdiomas());
    }
  }, [meusDados?.idioma, dispatch]);

  
  //console.log("MEUSDADOS", meusDados)  //AQUI MOSTRA MEUSDADOS
  //console.log("MEUSDADOSPERFIL", meusDados?.perfil.name)  //AQUI GERA PÁGINA EM BRANCO 
  //console.log("SISTEMAIDIOMAS", sistema.idiomas)

  const formRequired = {
    nome: '',
    sobrenome: '',
    email: 'email',
    celular: '',
    idioma: '',
    perfil: '',
  }

  const handleChange = e => {
    dispatch(setInfoMeusDados(e))
    
  }

  const handleSee = e => {
    setSenhaVisivel(e.value)
  }
  
  return (
    <div className='box-meusdados'>
      <div className='colflex col2'>
        <Input
          name='nome'
          label={nls?.formNome}
          value={meusDados?.nome}
          placeholder={nls?.formNomePH}
          action={(e) => handleChange(e)}
          maxLength={50}
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.nome
          }}
        />
        <Input
          name='sobrenome'
          label={nls?.formSobrenome}
          value={meusDados?.sobrenome}
          placeholder={nls?.formSobrenomePH}
          action={(e) => handleChange(e)}
          maxLength={50}
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.sobrenome
          }}
        />

        <Input
          name='email'
          label={nls?.formEmail}
          value={meusDados?.email}
          placeholder={nls?.formEmailPH}
          action={(e) => handleChange(e)}
          maxLength={50}
          disabled={true}
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.email
          }}
        />
        <Input
          name='celular'
          label={nls?.formCelular}
          value={meusDados?.celular}
          placeholder={nls?.formCelularPH}
          action={(e) => handleChange(e)}
          maxLength={50}
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.celular
          }}
        />

        <Input
          name='perfil'
          label={<>{nls?.formPerfil} <PopoverCentralAjuda nlsContexto={nlsContexto} idarray={idarray} /></>}
          value={meusDados?.perfil?.name}
          placeholder={nls?.formPerfilPH}
          action={(e) => handleChange(e)}
          maxLength={50}
          disabled={true}
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.perfil
          }}
        />

        <SelectDefault
          name='idioma'
          label={nls?.formIdioma}
          options={sistema.idiomas}
          selected={verifySelectValue({value: meusDados?.idioma.idiomaId?meusDados?.idioma.idiomaId:meusDados?.idioma.id, list: sistema.idiomas, val: 'idiomaId'})}
          action={(e) => handleChange(e)}
          selectedItem={false}
          optionLabel='descricaoIdioma'
          optionValue='idioma'
          required={{
            erro,
            message: nls.mensagem.obrigatorio,
            pattern: formRequired.idioma
          }}
        />
        
      </div>

    </div>
  )
}
