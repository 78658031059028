import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './centralAjuda.scss';
import { useEffect } from 'react';
import { Button, IcoArrowRigth, IcoClose, IcoEnvelope, ModalRight } from '../../components';
import { listarCentralAjuda, setCloseCentralAjuda } from './redux/centralAjudaActions';
import { modalOpen } from '../../layout/redux/layoutActions';
import ContatoSuporte from './contatoSuporte';


export default () => {
  const dispatch = useDispatch()
  const { sistema } = useSelector(state => state.layoutState)
  const nls = require(`./nls/${sistema.idioma}.json`)
  const { centralAjuda, open } = useSelector(state=> state.centralAjudaState)

  

  //console.log("NLS EM CENTRAL AJUDA",nls)
  useEffect(() => {
    
    console.log("IDIOMA",sistema.idioma)
    console.log("NLS EM CENTRAL AJUDA",nls)

    dispatch(listarCentralAjuda([1,2,3,4],nls)); // Inicializa com IDs 1 e 3

    const handleLinkClick = (e) => {
      if (e.target.classList.contains('link')) {
        e.preventDefault(); // Previne o comportamento padrão do link
        const ids = e.target.dataset.ids; // Obtém os IDs do atributo data-ids
        if (ids) {
          const idArray = ids.split(',').map((id) => parseInt(id, 10)); // Converte para array de números
          dispatch(listarCentralAjuda(idArray,nls)); // Atualiza a lista com os novos IDs
        }
      }
    };

    // Adiciona o evento de clique para capturar links
    document.addEventListener('click', handleLinkClick);

    // Cleanup do evento ao desmontar o componente
    return () => {
      document.removeEventListener('click', handleLinkClick);
    };
  }, [dispatch, nls]);

  const handleClose = e => {
    dispatch(setCloseCentralAjuda())
  }

  const handleOpenSuporte = e => {
    dispatch(modalOpen('contatoSuporteModal'))
    handleClose()
  }


  //linha 52: <p>{e.conteudo}</p>
  return (<>
    <ModalRight
      open={open}
    >
      <div className='box-central-ajuda'>
        <div>
          <div className='box-central-ajuda-title'>
            <h4>{nls.title}</h4>
            <div>
              <Button
                type='link'
                onClick={() => handleClose()}
              ><IcoClose /></Button>
            </div>
          </div>

          <div className='box-central-ajuda-content'>
              {centralAjuda.map(e=> {
                return <div key={e.id} className='box-central-ajuda-content-info'>
                  <h6 
                    className='custom-spacing'
                    style={{ color: 'rgb(50, 58, 204)' }}
                    dangerouslySetInnerHTML={{ __html: e.titulo }}
                  ></h6>
                  <p 
                    className='custom-spacing textosite'
                    dangerouslySetInnerHTML={{ __html: e.conteudo }}
                  ></p>
                  
                </div>
              })}
          </div>
        </div>
        
      </div>
    </ModalRight>
    <ContatoSuporte />
  </>)
}